import { useTranslation } from 'react-i18next';
import { Link, useLocation, useRoute } from 'wouter';
import { ReactComponent as ActiveIcon } from '../../assets/icons/Text field icons/Active icon.svg';
import { ReactComponent as InactiveIcon } from '../../assets/icons/Text field icons/Inactive icon.svg';
import { useCompany } from '../../shared/appUIFramework/appBackend/useCompany';
import {
  deleteEntryUser,
  useEntryUsersTotals,
} from '../../shared/appUIFramework/appBackend/useEntryUsers';
import { AppPermissionComponentGuard } from '../../shared/appUIFramework/appPermisions/AppPermisionGuard';
import {
  CanNavigateToCreateEntryUserPermissions,
  CanNavigateToDeleteEntryUserPermissions,
  CanNavigateToEditEntryUserPermissions,
} from '../../shared/appUIFramework/appPermisions/permissionsGroups';
import { RegistrationStatus } from '../../shared/appUIFramework/constants/RegistrationStatus';
import { SortStringOptions } from '../../shared/appUIFramework/hooks/useSortStringOptions';
import BulkDeleteUsersConfirmationPopup from './components/BulkDeleteUsersConfirmationPopup';
import DeleteEntryUserConfirmationPopup from './components/DeleteEntryUserConfirmationPopup';
import DeleteEntryUserErrorPopup from './components/DeleteEntryUserErrorPopup';
import DeleteEntryUserSuccessPopup from './components/DeleteEntryUserSuccessPopup';
import SystemTabsHeader from './components/SystemTabsHeader';
import { SortNumericOptions } from '../../shared/appUIFramework/hooks/useSortNumericOptions';
import { Urls } from '../../shared/backend/urls';
import {
  AccountStatusUIState,
  applyFunctionalityLimitedStyles,
  useAccountInfo,
} from '../../shared/appUIFramework/appBackend/useAccountInfo';
import AppSystemErrorPopup from '../../shared/appUIFramework/appGenericErrorPopup/AppSystemErrorPopup';
import {
  UserStatusTooltipBody,
  UserLicenseTooltipBody,
  UserIdTooltipBody, UserGroupTooltipBody,
} from './components/EntryUserFieldTooltipComponents';
import BulkDeleteEntryUsersSuccessPopup from './components/BulkDeleteEntryUsersSuccessPopup';
import { IEntryUserGridItem, IEntryUsersTotals } from './models/IUserData';
import AppTablePage from '../../shared/appUIFramework/components/AppTablePage';
import { hasAnyOfPermissions } from '../../shared/appUIFramework/appPermisions/useAppPermissions';
import { IGridQueryParams } from './models/IGridQueryParams';
import './SystemTabsEntryUsers.scss';

interface IUsersGridQueryParams extends IGridQueryParams {
  nameSort?: SortStringOptions;
  isActive?: boolean;
  registrationStatus?: RegistrationStatus;
  monitorSort?: SortNumericOptions;
}

enum BoolSortOptions {
  NotSet = 'undefined',
  True = 'true',
  False = 'false',
}

export default function SystemTabsEntryUsers() {
  const { t } = useTranslation();
  const [, params] = useRoute('/systems/:siteId/:tab');
  const company = useCompany();
  const { accountInfo: { accountStatus } } = useAccountInfo();
  const isAccountFunctionalityLimited = accountStatus === AccountStatusUIState.Limited;
  const [, setLocation] = useLocation();
  const goToEntryUserDetails = (itemId: string) => {
    setLocation(`/systems/${params?.siteId}/entry-users/${itemId}`);
  };

  return (
    <>
      <SystemTabsHeader activeTab="entry-users" />
      <AppTablePage<IEntryUserGridItem, IUsersGridQueryParams, IEntryUsersTotals>
        inTabsShell
        searchText={t('Sites_SearchEntryUsers')}
        normalModeActions={(
          <AppPermissionComponentGuard permissions={CanNavigateToCreateEntryUserPermissions}>
            {(isAccountFunctionalityLimited && (
              <span className="app-systems-table-header app-primary-button disabled-green" {...applyFunctionalityLimitedStyles(true)}>
                {t('Sites_AddNewMobile')}
              </span>
            ))
              || (
                <Link
                  to={`/systems/${params?.siteId}/entry-users/0`}
                  className="app-primary-button"
                >
                  {t('Sites_AddNewMobile')}
                </Link>
              )}
          </AppPermissionComponentGuard>
        )}
        table={{
          query: {
            itemsUrl: Urls.UsersWithSiteId(params?.siteId),
            initialQueryParams: {
              search: '',
              nameSort: SortStringOptions.Ascending.toString() as any,
            },
            useTotals: (queryParams: IUsersGridQueryParams) => {
              const { usersTotals: totals, mutate } = useEntryUsersTotals(params?.siteId, {
                nameSort: queryParams.nameSort,
                isActive: queryParams.isActive,
                registrationStatus: queryParams.registrationStatus,
              });

              const reduceTotals = (item: IEntryUserGridItem) => mutate({
                totalUsers: totals!.totalUsers - 1,
                totalActiveUsers: item.isActive
                  ? totals!.totalActiveUsers - 1
                  : totals!.totalActiveUsers,
              }, { revalidate: false });

              return {
                totals,
                totalCount: totals?.totalUsers || 0,
                reduceTotals,
                getTotalValues: ({ totalUsers, totalActiveUsers }) => ([
                  {
                    label: t('Info_ActiveUsers'),
                    value: totalActiveUsers,
                  },
                  {
                    label: t('Info_TotalUsers'),
                    value: totalUsers,
                  },
                ]),
              };
            },
          },
          goToItem: (item) => hasAnyOfPermissions(...CanNavigateToEditEntryUserPermissions) && goToEntryUserDetails(item.id),
          remove: {
            removeItemFromBackend: (itemId) => deleteEntryUser(itemId, company?.id, params?.siteId),
            requiredPermissions: CanNavigateToDeleteEntryUserPermissions,
            bulkDelete: {
              ConfirmationPopup: BulkDeleteUsersConfirmationPopup,
              SuccessPopup: BulkDeleteEntryUsersSuccessPopup,
              ErrorPopup: AppSystemErrorPopup,
            },
            singleDelete: {
              ConfirmationPopup: ({ item: { name, isActive } }: { item: IEntryUserGridItem }) => (
                <DeleteEntryUserConfirmationPopup
                  user={name}
                  status={isActive
                    ? t('FieldValue_Active')
                    : t('FieldValue_Inactive')}
                />
              ),
              SuccessPopup: ({ item: { name } }: { item: IEntryUserGridItem }) => (
                <DeleteEntryUserSuccessPopup
                  userName={name}
                />
              ),
              ErrorPopup: DeleteEntryUserErrorPopup,
            },
          },
          customColumnsClass: {
            desktop: 'app-table-system-tabs-entry-users',
          },
          columns: [
            {
              title: t('Table_Name'),
              queryParamSetting: {
                key: 'nameSort',
                values: [
                  [SortStringOptions.Ascending, t('TableSort_ATOZ') as string],
                  [SortStringOptions.Descending, t('TableSort_ZTOA') as string],
                ],
                type: 'sort',
              },
              getValue: (item: IEntryUserGridItem) => item.name,
            },
            {
              title: t('Table_Email'),
              isHiddenOnMobile: true,
              getValue: (item: IEntryUserGridItem) => item.emailAddress,
            },
            {
              title: t('License'),
              queryParamSetting: {
                key: 'isActive',
                values: [
                  [BoolSortOptions.NotSet, t('TableSort_All') as string],
                  [BoolSortOptions.True, (
                    <div className="app-w-100pcnt app-d-flex app-gap-15 app-align-items-center">
                      <ActiveIcon className="app-table-col-green-icon" />
                      <span>{t('FieldValue_Active')}</span>
                    </div>
                  )],
                  [BoolSortOptions.False, (
                    <div className="app-w-100pcnt app-gap-15 app-d-flex app-align-items-center">
                      <InactiveIcon />
                      <span>{t('FieldValue_Inactive')}</span>
                    </div>
                  )],
                ],
                type: 'filter',
              },
              tooltipPopupTitle: t('Tooltip_UserLicenceTitle'),
              tooltip: <UserLicenseTooltipBody />,
              getValue: (item: IEntryUserGridItem) => (item.isActive
                ? t('FieldValue_Active')
                : t('FieldValue_Inactive')),
              getValueClass: () => 'app-w-fit-content',
              getIcon: (item: IEntryUserGridItem) => (item.isActive
                ? <ActiveIcon className="app-table-col-green-icon" />
                : <InactiveIcon />),
            },
            {
              title: t('Status'),
              queryParamSetting: {
                key: 'registrationStatus',
                values: [
                  [RegistrationStatus.All, t('TableSort_All') as string],
                  [RegistrationStatus.Pending, t('User_RegistrationPending') as string],
                  [RegistrationStatus.Registered, t('Paired') as string],
                  [RegistrationStatus.Unregistered, t('Unpaired') as string],
                ],
                type: 'filter',
              },
              tooltip: <UserStatusTooltipBody />,
              tooltipPopupTitle: t('Tooltip_UserStatusTitle'),
              getValue: (item: IEntryUserGridItem) => {
                switch (item.registrationStatus) {
                  case RegistrationStatus.Unknown:
                    return t('Unknown');
                  case RegistrationStatus.Registered:
                    return t('Paired');
                  case RegistrationStatus.Pending:
                    return t('User_RegistrationPending');
                  case RegistrationStatus.Unregistered:
                    return t('Unpaired');
                  default:
                    return '-';
                }
              },
            },
            {
              title: t('Table_Group'),
              isHiddenOnMobile: true,
              getValue: (item: IEntryUserGridItem) => item.group,
              tooltip: <UserGroupTooltipBody />,
              tooltipPopupTitle: t('Tooltip_UserGroupTitle'),
            },
            {
              title: t('Table_ID'),
              queryParamSetting: {
                key: 'monitorSort',
                values: [
                  [SortNumericOptions.Ascending, t('Ascending') as string],
                  [SortNumericOptions.Descending, t('Descending') as string],
                ],
                type: 'sort',
              },
              isHiddenOnMobile: true,
              getValue: (item: IEntryUserGridItem) => item.monitorId,
              tooltip: <UserIdTooltipBody />,
              tooltipPopupTitle: t('Tooltip_UserIDTitle'),
            },
          ],
        }}
      />
    </>
  );
}
