import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppLocalisation } from '../../i18n/config';
import { closePopup } from '../popup/AppPopup';
import callIfEnterKeyPressed from '../../jsUtils/callIfEnterKeyPressed';
import { B2CLangCode } from '../constants/B2CLangCode';

enum VIDEOS {
  HOW_TO_CREATE_SITE = 'HOW_TO_CREATE_SITE',
  HOW_TO_ADD_MOBILE_APP_USER = 'HOW_TO_ADD_MOBILE_APP_USER',
  HOW_TO_PAY_INVOICE = 'HOW_TO_PAY_INVOICE',
  HOW_TO_INVITE_ADMINISTRATORS = 'HOW_TO_INVITE_ADMINISTRATORS',
  HOW_TO_SETUP_MOBILE_USER = 'HOW_TO_SETUP_MOBILE_USER',
}

interface IVideoConfig {
  titleKey: string,
  localizedLinks: Record<B2CLangCode, string>
}

const HOW_TO_CREATE_SITE_ENGLISH = 'https://www.Paxton.info/6822';
const HOW_TO_ADD_MOBILE_APP_USER_ENGLISH = 'https://www.Paxton.info/6823';
const HOW_TO_PAY_INVOICE_ENGLISH = 'https://www.Paxton.info/6824';
const HOW_TO_INVITE_ADMINISTRATORS_ENGLISH = 'https://www.Paxton.info/6825';
const HOW_TO_SETUP_MOBILE_USER_ENGLISH = 'https://www.Paxton.info/6826';

const VIDEO_CONFIG: Record<VIDEOS, IVideoConfig> = {
  [VIDEOS.HOW_TO_CREATE_SITE]: {
    titleKey: 'HowToCreateASiteInMyPaxton',
    localizedLinks: {
      [B2CLangCode.English]: HOW_TO_CREATE_SITE_ENGLISH,
      [B2CLangCode.EnglishUS]: 'www.paxton.info/6687',
      [B2CLangCode.French]: 'https://www.Paxton.info/6827',
      [B2CLangCode.German]: 'https://www.Paxton.info/6832',
      [B2CLangCode.Netherlands]: 'https://www.Paxton.info/6837',
      [B2CLangCode.Swedish]: 'https://www.Paxton.info/6842',
      [B2CLangCode.Danish]: HOW_TO_CREATE_SITE_ENGLISH,
      [B2CLangCode.Norwegian]: HOW_TO_CREATE_SITE_ENGLISH,
      [B2CLangCode.SpanishLatinAmerica]: HOW_TO_CREATE_SITE_ENGLISH,
    },
  },
  [VIDEOS.HOW_TO_ADD_MOBILE_APP_USER]: {
    titleKey: 'HowToAddMobileAppUsersToYourSite',
    localizedLinks: {
      [B2CLangCode.English]: HOW_TO_ADD_MOBILE_APP_USER_ENGLISH,
      [B2CLangCode.EnglishUS]: 'http://www.paxton.info/6888',
      [B2CLangCode.French]: 'https://www.Paxton.info/6828',
      [B2CLangCode.German]: 'https://www.Paxton.info/6833',
      [B2CLangCode.Netherlands]: 'https://www.Paxton.info/6838',
      [B2CLangCode.Swedish]: 'https://www.Paxton.info/6843',
      [B2CLangCode.Danish]: HOW_TO_ADD_MOBILE_APP_USER_ENGLISH,
      [B2CLangCode.Norwegian]: HOW_TO_ADD_MOBILE_APP_USER_ENGLISH,
      [B2CLangCode.SpanishLatinAmerica]: HOW_TO_ADD_MOBILE_APP_USER_ENGLISH,
    },
  },
  [VIDEOS.HOW_TO_PAY_INVOICE]: {
    titleKey: 'HowToPayInvoices',
    localizedLinks: {
      [B2CLangCode.English]: HOW_TO_PAY_INVOICE_ENGLISH,
      [B2CLangCode.EnglishUS]: 'http://www.paxton.info/6889',
      [B2CLangCode.French]: 'https://www.Paxton.info/6829',
      [B2CLangCode.German]: 'https://www.Paxton.info/6834',
      [B2CLangCode.Netherlands]: 'https://www.Paxton.info/6839',
      [B2CLangCode.Swedish]: 'https://www.Paxton.info/6844',
      [B2CLangCode.Danish]: HOW_TO_PAY_INVOICE_ENGLISH,
      [B2CLangCode.Norwegian]: HOW_TO_PAY_INVOICE_ENGLISH,
      [B2CLangCode.SpanishLatinAmerica]: HOW_TO_PAY_INVOICE_ENGLISH,
    },
  },
  [VIDEOS.HOW_TO_INVITE_ADMINISTRATORS]: {
    titleKey: 'HowToInviteAdministratorsIntoYourMyPaxton',
    localizedLinks: {
      [B2CLangCode.English]: HOW_TO_INVITE_ADMINISTRATORS_ENGLISH,
      [B2CLangCode.EnglishUS]: 'http://www.paxton.info/6890',
      [B2CLangCode.French]: 'https://www.Paxton.info/6830',
      [B2CLangCode.German]: 'https://www.Paxton.info/6835',
      [B2CLangCode.Netherlands]: 'https://www.Paxton.info/6840',
      [B2CLangCode.Swedish]: 'https://www.Paxton.info/6845',
      [B2CLangCode.Danish]: HOW_TO_INVITE_ADMINISTRATORS_ENGLISH,
      [B2CLangCode.Norwegian]: HOW_TO_INVITE_ADMINISTRATORS_ENGLISH,
      [B2CLangCode.SpanishLatinAmerica]: HOW_TO_INVITE_ADMINISTRATORS_ENGLISH,
    },
  },
  [VIDEOS.HOW_TO_SETUP_MOBILE_USER]: {
    titleKey: 'HowToSetupAMobileAppUser',
    localizedLinks: {
      [B2CLangCode.English]: HOW_TO_SETUP_MOBILE_USER_ENGLISH,
      [B2CLangCode.EnglishUS]: 'http://www.paxton.info/6891',
      [B2CLangCode.French]: 'https://www.Paxton.info/6831',
      [B2CLangCode.German]: 'https://www.Paxton.info/6836',
      [B2CLangCode.Netherlands]: 'https://www.Paxton.info/6841',
      [B2CLangCode.Swedish]: 'https://www.Paxton.info/6846',
      [B2CLangCode.Danish]: HOW_TO_SETUP_MOBILE_USER_ENGLISH,
      [B2CLangCode.Norwegian]: HOW_TO_SETUP_MOBILE_USER_ENGLISH,
      [B2CLangCode.SpanishLatinAmerica]: HOW_TO_SETUP_MOBILE_USER_ENGLISH,
    },
  },
};

const VIDEOS_ARRAY = [
  VIDEOS.HOW_TO_CREATE_SITE,
  VIDEOS.HOW_TO_ADD_MOBILE_APP_USER,
  VIDEOS.HOW_TO_PAY_INVOICE,
  VIDEOS.HOW_TO_INVITE_ADMINISTRATORS,
  VIDEOS.HOW_TO_SETUP_MOBILE_USER,
];

const DEFAULT_VIDEO = VIDEOS.HOW_TO_CREATE_SITE;

export default function AppHelpPopup() {
  const { t } = useTranslation();
  const [video, setVideo] = useState<VIDEOS | null>(DEFAULT_VIDEO);
  const videoConfig = VIDEO_CONFIG[video ?? DEFAULT_VIDEO];
  const isVideoIdNotNull = video != null;
  const clearVideoId = () => setVideo(null);
  const { lang } = useAppLocalisation();

  return (
    <div className="app-popup-content app-help-popup-content">
      <div className="app-help-popup-content-header">
        {isVideoIdNotNull
          ? (
            <>
              <div className="app-d-flex app-font-20 app-weight-600 app-uppercase app-color-primary-green">
                {t('Popup_GettingToKnowMyPaxton')}
              </div>
              <div className="app-font-20 app-uppercase app-weight-600">{t('Popup_WatchGettingStartedVideo')}</div>
            </>
          )
          : <div className="app-help-popup-title app-font-20 app-weight-600 app-uppercase">{t('Popup_VideoTutorials')}</div>}
      </div>
      {isVideoIdNotNull ? (
        <>
          <div className="app-single-video-container">
            <iframe
              className="app-single-video"
              src={videoConfig.localizedLinks[lang]}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />

          </div>
          <div className="app-popup-buttons">
            <button
              type="button"
              onClick={closePopup}
              className="app-secondary-button app-popup-left-button"
            >
              {t('Popup_CloseHelp')}
            </button>
            <button
              type="button"
              onClick={clearVideoId}
              className="app-primary-button app-popup-right-button"
            >
              {t('Popup_MoreVideos')}
            </button>
          </div>
        </>
      )
        : (
          <>
            <div className="app-help-video-items">
              {VIDEOS_ARRAY.map((videoArrayItem) => (
                <div
                  className="app-help-video-item"
                  role="button"
                  onClickCapture={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setVideo(videoArrayItem);
                  }}
                  onKeyDown={callIfEnterKeyPressed(() => setVideo(videoArrayItem))}
                  tabIndex={0}
                  key={videoArrayItem}
                >
                  <iframe
                    width="312"
                    height="228"
                    src={VIDEO_CONFIG[videoArrayItem].localizedLinks[lang]}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                  <div className="app-help-video-item-title">
                    {t(VIDEO_CONFIG[videoArrayItem].titleKey)}
                  </div>
                </div>
              ))}
            </div>
            <div className="app-popup-buttons">
              <button
                type="button"
                onClick={closePopup}
                className="app-secondary-button app-popup-left-button"
              >
                {t('Popup_CloseHelp')}
              </button>
            </div>
          </>
        )}
    </div>
  );
}
