import { useCallback, useRef, useState } from 'react';
import TooltipIcon from '../../../assets/icons/Tooltip icon.svg';
import { useIsMobileLayout } from '../hooks/useIsMobileLayout';
import { showPopup } from '../popup/AppPopup';
import AppOutsideListener from './AppOutsideListener';
import AppPopupTooltip from './AppPopupTooltip';
import './AppTooltipSignup.scss';

export default function AppTooltipSignup({
  tooltip,
  onTooltipStateChanged,
}: { tooltip: { tooltipTitle: string, title?: string, description: string }, onTooltipStateChanged?: (state: boolean) => void }) {
  const [shown, setShown] = useState(false);
  const { isMobileLayout } = useIsMobileLayout();

  const updateTooltip = (state: boolean) => {
    if (state && isMobileLayout) {
      showPopup(<AppPopupTooltip
        title={tooltip.tooltipTitle}
        body={
          (
            <>
              <div>
                {tooltip.description}
              </div>
              <div className="app-signup-popup-tooltip-title">
                {tooltip.title}
              </div>
            </>
          )
        }
      />);
      return;
    }

    setShown(state);
    if (onTooltipStateChanged) {
      onTooltipStateChanged(state);
    }
  };

  const hide = useCallback((e: any) => {
    e.preventDefault();
    e.stopPropagation();
    updateTooltip(false);
  }, []);

  const toggle = useCallback((e: any) => {
    e.preventDefault();
    e.stopPropagation();
    updateTooltip(!shown);
  }, [shown]);

  const tooltipRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={tooltipRef}
      className="app-signup-variant-item-tooltip-trigger"
    >
      <AppOutsideListener
        inputRef={tooltipRef}
        onClick={hide}
      >
        <div
          ref={tooltipRef}
          className="app-d-inline-block"
        >
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <img
            onClick={toggle}
            src={TooltipIcon}
            alt="info icon"
          />
          {shown && (
            <div className="app-signup-variant-item-tooltip">
              <svg className="app-signup-variant-item-tooltip-arrow">
                <path
                  d="M3 17 L10 3 L17 17"
                  stroke="green"
                />
              </svg>
              <div className="app-signup-variant-item-tooltip-description">
                {tooltip.description}
              </div>
              {tooltip.title && (
                <div className="app-signup-variant-item-tooltip-title">
                  {tooltip.title}
                </div>
              )}
            </div>
          )}
        </div>
      </AppOutsideListener>
    </div>
  );
}
