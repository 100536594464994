import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { ReactComponent as DeFlag } from '../../assets/DE-Flag.svg';
import { ReactComponent as FrFlag } from '../../assets/F-Flag.svg';
import { ReactComponent as CollapseIcon } from '../../assets/icons/Button-Control icons/Collaspe icon.svg';
import { ReactComponent as ExpandIcon } from '../../assets/icons/Button-Control icons/Expand icon.svg';
import { ReactComponent as NlFlag } from '../../assets/NL-Flag.svg';
import { ReactComponent as UkFlag } from '../../assets/UK-Flag.svg';
import { ReactComponent as UsFlag } from '../../assets/US-FLag.svg';
import { ReactComponent as IrFlag } from '../../assets/IR-Flag.svg';
import { ReactComponent as CaFlag } from '../../assets/CA-Flag.svg';
import { ReactComponent as LuFlag } from '../../assets/LU-Flag.svg';
import { ReactComponent as NoFlag } from '../../assets/No-Flag.svg';
import { ReactComponent as SeFlag } from '../../assets/SE-Flag.svg';
import { ReactComponent as ZaFlag } from '../../assets/ZA-Flag.svg';
import { ReactComponent as DkFlag } from '../../assets/DK-FLag.svg';
import { ReactComponent as BeFlag } from '../../assets/BE-Flag.svg';
import AppPopupMenu from '../../shared/appUIFramework/components/AppPopupMenu';
import AppTooltipSignup from '../../shared/appUIFramework/components/AppTooltipSignup';
import SignupLayout from './components/SignupLayout';
import { Region } from './signup.models';
import './SignUpRegion.scss';
import { useSignUpState } from './hooks/useSignupState';

function useRegionLabels(): {
  regions: string[],
  getRegionLabel: (region: Region) => ReactNode,
  } {
  const { t } = useTranslation();
  const labels: Record<Region, ReactNode> = {
    [Region.UnitedKingdom]:
  <div className="app-region-label">
    <UkFlag />
    <span>{ t('UnitedKingdom') }</span>
  </div>,
    [Region.France]:
  <div className="app-region-label">
    <FrFlag />
    <span>{ t('France') }</span>
  </div>,
    [Region.Germany]:
  <div className="app-region-label">
    <DeFlag />
    <span>{ t('Germany') }</span>
  </div>,
    [Region.Netherlands]:
  <div className="app-region-label">
    <NlFlag />
    <span>{ t('Netherlands') }</span>
  </div>,
    [Region.UnitedStates]:
  <div className="app-region-label">
    <UsFlag />
    <span>{ t('UnitedStates') }</span>
  </div>,
    [Region.Ireland]:
  <div className="app-region-label">
    <IrFlag />
    <span>{ t('Ireland') }</span>
  </div>,
    [Region.Luxembourg]:
  <div className="app-region-label">
    <LuFlag />
    <span>{ t('Luxembourg') }</span>
  </div>,
    [Region.Belgium]:
  <div className="app-region-label">
    <BeFlag />
    <span>{ t('Belgium') }</span>
  </div>,
    [Region.SouthAfrica]:
  <div className="app-region-label">
    <ZaFlag />
    <span>{ t('SouthAfrica') }</span>
  </div>,
    [Region.Canada]:
  <div className="app-region-label">
    <CaFlag />
    <span>{ t('Canada') }</span>
  </div>,
    [Region.Sweden]:
  <div className="app-region-label">
    <SeFlag />
    <span>{ t('Sweden') }</span>
  </div>,
    [Region.Norway]:
  <div className="app-region-label">
    <NoFlag />
    <span>{ t('Norway') }</span>
  </div>,
    [Region.Denmark]:
  <div className="app-region-label">
    <DkFlag />
    <span>{ t('Denmark') }</span>
  </div>,
  };
  const options = Object.keys(labels);
  const getOptionLabel = (option: Region) => labels[option];

  return {
    regions: options,
    getRegionLabel: getOptionLabel,
  };
}

export default function SignUpRegion() {
  const { t } = useTranslation();
  const {
    signUpState,
    init,
  } = useSignUpState();

  useEffect(() => {
    init();
  }, []);

  const [region, setRegion] = useState<Region | null>();
  const { regions, getRegionLabel } = useRegionLabels();
  const [errorMessage, setErrorMessage] = useState('');
  const [, setLocation] = useLocation();

  const goToWelcome = () => setLocation('/welcome');
  const tryGoToSignUpVariant = () => {
    if (region) {
      signUpState.region = region;
      setLocation('/signup/variant');
    } else {
      setErrorMessage(t('PleaseSelectRegionToProceed'));
    }
  };

  return (
    <SignupLayout className="app-signup-layout">
      <div className="app-d-flex app-flex-column app-justify-content-center app-gap-23 app-align-items-center app-position-relative">
        <AppPopupMenu
          options={regions}
          selectedOption={region}
          onOptionSelected={setRegion}
          getOptionLabel={getRegionLabel as (option: string) => string}
          render={(isHidden) => (
            <>
              <div>
                <div className="app-signup-region-container app-d-flex app-justify-content-between app-align-items-center">
                  { region
                    ? getRegionLabel(region)
                    : <span className="app-text-transform-capitalize">{ t('SelectRegion') }</span> }
                  { isHidden ? <ExpandIcon /> : <CollapseIcon /> }
                </div>
                { !region && errorMessage
                    && <span className="app-signup-input-error app-signup-input-error-padding-select">{ errorMessage }</span> }
              </div>
            </>
          )}
        />
        <div className="app-signup-region-tooltip">
          <AppTooltipSignup tooltip={{
            tooltipTitle: t('SelectRegion'),
            description: t('PaxtonIsNotAvailableForAllCountries'),
          }}
          />
        </div>

        <button
          className="app-primary-button"
          type="button"
          onClick={tryGoToSignUpVariant}
        >
          { t('Continue') }
        </button>
        <button
          className="app-link-primary-button"
          type="button"
          onClick={goToWelcome}
        >
          { t('Back') }
        </button>
      </div>
    </SignupLayout>
  );
}
