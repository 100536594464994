import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { ReactComponent as InstallerIcon } from '../../assets/Installer icon.svg';
import { ReactComponent as ResellersIcon } from '../../assets/Resellers icon.svg';
import AppTooltipSignup from '../../shared/appUIFramework/components/AppTooltipSignup';
import SignupLayout from './components/SignupLayout';
import { useSignUpState } from './hooks/useSignupState';
import { Region } from './signup.models';
import './SignUpVariant.scss';

function SignUpVariantItem({
  icon, title, description, tooltip, onClick, onTooltipStateChanged, tooltipVisible = true,
}: {
  icon: ReactNode, title: string, description: string, tooltip: {
    title: string,
    description: string
    tooltipTitle: string
  },
  onClick?: () => void,
  onTooltipStateChanged: (state: boolean) => void,
  tooltipVisible?: boolean,
}) {
  const isClickable = !!onClick;

  return (
    <div
      tabIndex={0}
      role="button"
      onKeyUp={onClick}
      className={`app-signup-variant-item ${isClickable ? 'app-cursor-pointer' : 'app-cursor-arrow'}`}
      onClick={onClick}
    >
      <div className="app-signup-variant-item-icon">
        { icon }
      </div>
      <div className="app-signup-variant-item-body">
        <div className="app-signup-variant-item-title">
          <span>{ title }</span>
          {tooltipVisible && <AppTooltipSignup tooltip={tooltip} onTooltipStateChanged={onTooltipStateChanged} />}
        </div>
        <div className="app-signup-variant-item-description">
          { description }
        </div>
      </div>
    </div>
  );
}

function getInstallersTranslationKey(region: Region): string {
  switch (region) {
    case Region.UnitedStates:
      return 'Dealers';
    default:
      return 'Installers';
  }
}

export default function SignUpVariant() {
  const [, setLocation] = useLocation();
  const goToSignUpInstaller = () => setLocation('/signup/installer');
  const goToSignUpVatInstaller = () => setLocation('/signup/vat-installer');
  const goToWelcome = () => setLocation('/welcome');
  const { t } = useTranslation();
  const [canNavigate, setCanNavigate] = useState(true);
  const [resellersTooltipVisible, setResellersTooltipVisible] = useState(true);
  const { signUpState: { region } } = useSignUpState();

  return (
    <SignupLayout className="app-signup-variant">
      <div className="app-d-flex app-flex-column app-justify-content-center app-gap-23 app-align-items-center">
        <SignUpVariantItem
          icon={<InstallerIcon />}
          title={t(getInstallersTranslationKey(region!))}
          description={t('InstallationCompanies')}
          tooltip={{
            description: t('DoesYourCompanySellAndInstall'),
            title: t('YouAreInstaller'),
            tooltipTitle: t('Installer'),
          }}
          onTooltipStateChanged={(tooltipShown) => {
            setCanNavigate(!tooltipShown);
            setResellersTooltipVisible(!tooltipShown);
          }}
          onClick={canNavigate ? goToSignUpInstaller : undefined}
        />
        <SignUpVariantItem
          icon={<ResellersIcon />}
          title={t('VATRegisteredResellers')}
          description={t('PropertyManagementVatResellers')}
          tooltip={{
            description: t('DoesCompanyOfferManagementService'),
            title: `${t('YouAreVATRegisteredReseller')}.`,
            tooltipTitle: t('VATRegisteredResellers'),
          }}
          tooltipVisible={resellersTooltipVisible}
          onTooltipStateChanged={(tooltipShown) => {
            setCanNavigate(!tooltipShown);
          }}
          onClick={canNavigate ? goToSignUpVatInstaller : undefined}
        />
        <button
          type="button"
          className={`app-link-primary-button ${canNavigate ? 'app-cursor-pointer' : 'app-cursor-arrow'}`}
          onClick={canNavigate ? goToWelcome : undefined}
        >
          {t('Back')}
        </button>
      </div>
    </SignupLayout>
  );
}
