import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { closePopup } from '../popup/AppPopup';
import { AppPopupLayout } from '../popup/AppPopupLayout';
import './AppPopupTooltip.scss';

export default function AppPopupTooltip({ title, body }: { title: string, body: ReactNode }) {
  const { t } = useTranslation();

  return (
    <AppPopupLayout
      extraClass="app-popup-tooltip"
      popupBody={body}
      headerTitle={title}
      buttons={[
        <button key="button" type="button" className="app-secondary-button app-popup-left-button" onClick={closePopup}>
          {t('Close')}
        </button>,
      ]}
    />
  );
}
