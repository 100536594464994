import { useEffect, useState } from 'react';

export function useIsMobileLayout(customWidth: number = 800) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= customWidth);

  useEffect(() => {
    const screenSizeHandler = () => {
      if (window.innerWidth <= 800 && !isMobile) {
        setIsMobile(true);
      } else if (window.innerWidth > 800 && isMobile) {
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', screenSizeHandler);
    return () => window.removeEventListener('resize', screenSizeHandler);
  }, [isMobile]);

  return { isMobileLayout: isMobile };
}
